<template>
  <div>
    <div class="evaluation-button-div mb-2">
      <b-button
        v-if="allows_crud"
        class="mx-1"
        size="sm"
        @click="
          $bvModal.show(`add-egressprofile-file-modal-${egress_profile_id}`)
        "
        >Agregar Anexo</b-button
      >
    </div>
    <b-table-simple small responsive>
      <b-thead>
        <b-tr>
          <b-th
            style="width: 3%"
            class="text-center"
            v-if="allows_crud && draggableList.length > 0"
          >
          </b-th>
          <b-th style="width: 15%" class="text-center">
            <strong> Archivos </strong>
          </b-th>
          <b-th
            style="width: 2%"
            v-if="allows_crud && draggableList.length > 0"
          ></b-th>
        </b-tr>
      </b-thead>
      <draggable
        v-model="draggableList"
        :disabled="!allows_crud"
        tag="b-tbody"
        handle=".handle"
      >
        <template v-if="draggableList.length > 0">
          <b-tr
            v-for="egress_profile_file in draggableList"
            :key="egress_profile_file.id"
          >
            <b-td
              class="text-center handle"
              :class="{ grabbable: allows_crud }"
              v-if="allows_crud"
              ><b-icon
                v-if="allows_crud"
                icon="arrows-expand"
                scale="1.2"
              ></b-icon
            ></b-td>
            <td class="text-left">
              <div class="ml-3">
                <template
                  v-if="files.find((x) => x.id == egress_profile_file.file)"
                >
                  <b-link
                    :href="
                      files.find((x) => x.id == egress_profile_file.file).file
                    "
                    target="_blank"
                  >
                    {{ extractName(egress_profile_file.file) }}
                  </b-link>
                </template>
              </div>
            </td>
            <b-td class="text-center" v-if="allows_crud">
              <button-delete
                v-b-tooltip.v-secondary.noninteractive.top="'Eliminar Anexo'"
                @click="deleteEgressProfileFile(egress_profile_file)"
              ></button-delete>
            </b-td>
          </b-tr>
        </template>
        <b-tr v-else>
          <b-td class="text-center" colspan="8">No hay anexos mostrar.</b-td>
        </b-tr>
      </draggable>
    </b-table-simple>
    <b-modal
      :id="`add-egressprofile-file-modal-${egress_profile_id}`"
      title="Agregar Anexo"
      size="md"
      hide-footer
    >
      <b-form-file
        v-model="file"
        :state="
          Boolean(file) &&
          file &&
          file.size <= max_file_size &&
          validateWordFile()
        "
        aria-describedby="input-file-feedback"
        placeholder="Seleccione un archivo Word(.docx) o arrástrelo aquí..."
        accept=".docx"
        browse-text="Buscar"
      >
      </b-form-file>
      <span
        v-if="!(file && file.size > max_file_size)"
        class="ml-1"
        style="font-size: 11px"
        >El archivo no puede superar
        {{ $max_size_in_files ? $max_size_in_files.max_size_text : "6MB" }} de
        tamaño.</span
      >
      <b-form-invalid-feedback id="input-file-feedback">
        <span v-if="file && file.size > max_file_size">
          El archivo no puede superar
          {{ $max_size_in_files ? $max_size_in_files.max_size_text : "6MB" }}
          de tamaño.
        </span>
        <span v-if="file && !validateWordFile()">
          No es un archivo de Word(.docx) válido.
        </span>
      </b-form-invalid-feedback>
      <template
        v-if="
          file &&
          file.size <= max_file_size &&
          validateWordFile() &&
          !disable_button
        "
      >
        <b-button
          :disabled="file && file.size > max_file_size && !disable_button"
          @click="save"
          class="mt-2 float-right"
          >Guardar
        </b-button>
      </template>
      <template v-else>
        <b-button :disabled="true" class="mt-2 float-right">Guardar</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import * as names from "@/store/names";
// import { mapGetters } from "vuex";
// import { generateUniqueId } from "@/utils/utils";
import { showLoadingAlert } from "@/utils/utils";
export default {
  name: "EgressProfileFileView",
  components: {
    draggable,
  },
  props: {
    egress_profile_id: {
      type: Number,
      required: true,
    },
    allows_crud: {
      type: Boolean,
    },
  },
  data() {
    return {
      egress_profile_files: [],
      files: [],
      file: null,
      // Al cambiar el max_file_size asegurar modificar "El archivo no puede superar de tamaño"
      max_file_size: this.$max_size_in_files
        ? this.$max_size_in_files.max_size
        : 12582912,
      disable_button: false,
    };
  },
  computed: {
    // ...mapGetters({}),
    draggableList: {
      get() {
        let list = this.egress_profile_files;
        list.sort(function (a, b) {
          if (a.order < b.order) return -1;
          if (a.order > b.order) return 1;
          return 0;
        });
        let order = 1;
        list.forEach((element) => {
          if (element.order != order) element.order = order;
          order += 1;
        });
        return list;
      },
      set(list) {
        let order = 1;
        list.forEach((element) => {
          if (element.order != order) {
            element.order = order;
            if (!isNaN(element.id)) {
              this.patchOrderEgressProfileFile(element);
            }
          }
          order += 1;
        });
      },
    },
  },
  methods: {
    validateWordFile() {
      if (this.file) {
        const allowedExtensions = ["docx"]; // Extensiones permitidas
        const fileExtension = this.file.name.split(".").pop().toLowerCase(); // Obtener la extensión del archivo
        // Validar si la extensión del archivo está en la lista de extensiones permitidas
        if (allowedExtensions.indexOf(fileExtension) === -1) return false; // No es un archivo de Word válido
        return true; // Es un archivo de Word válido
      }
      return false;
    },
    save() {
      if (
        this.file &&
        this.file.size <= this.max_file_size &&
        this.validateWordFile()
      ) {
        this.disable_button = true;
        const formData = new FormData();
        formData.append("file", this.file);
        formData.append("name", this.file.name);
        // Alerta de en proceso....
        showLoadingAlert(
          "Subiendo archivo...",
          "Por favor, espera mientras se sube el archivo."
        );
        this.$store
          .dispatch(names.POST_FILE, formData)
          .then((file_response) => {
            this.files.push(file_response);
            this.$restful
              .Post(`/mesh/egress-profile-file/`, {
                egress_profile: this.egress_profile_id,
                file: file_response.id,
                order: this.draggableList.length + 1,
              })
              .then((response) => {
                this.$swal.close();
                this.disable_button = false;
                this.egress_profile_files.push(response);
                this.$bvModal.hide(
                  `add-egressprofile-file-modal-${this.egress_profile_id}`
                );
                this.file = null;
              })
              .catch(() => {
                this.disable_button = false;
                this.$swal.close();
                this.$swal.fire(
                  "Error",
                  "Hubo un problema al subir el archivo.",
                  "error"
                );
              });
          });
      }
    },
    patchOrderEgressProfileFile(egress_profile_file) {
      this.$restful
        .Patch(`/mesh/egress-profile-file/${egress_profile_file.id}/`, {
          order: egress_profile_file.order,
        })
        .then((response) => {
          const index = this.egress_profile_files.findIndex(
            (x) => x.id == response.id
          );
          if (index != -1) {
            this.egress_profile_files.splice(index, 1, response);
            this.$bvModal.hide(`edit-egressprofile-file-modal-${response.id}`);
          }
        });
    },
    deleteEgressProfileFile(egress_profile_file) {
      this.$swal({
        title: `¿Está seguro de que desea eliminar el anexo?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          this.$restful
            .Delete(`/mesh/egress-profile-file/${egress_profile_file.id}/`)
            .then(() => {
              this.$store.dispatch(names.DELETE_FILE, egress_profile_file.file);
              const index = this.egress_profile_files.findIndex(
                (x) => x.id == egress_profile_file.id
              );
              if (index != -1) {
                this.egress_profile_files.splice(index, 1);
              }
            });
        }
      });
    },
    extractName(egress_profile_file_id) {
      const file = this.files.find((x) => x.id == egress_profile_file_id);
      if (file) {
        return file.name;
      } else return "";
    },
    fetchEgressProfileFile() {
      this.$restful
        .Get(
          `/mesh/egress-profile-file/?egress_profile=${this.egress_profile_id}`
        )
        .then((response) => {
          this.egress_profile_files = response;
        });
    },
    fetchFiles() {
      this.$restful
        .Get(`/common/file/?egress_profile=${this.egress_profile_id}`)
        .then((response) => {
          this.files = response;
        });
    },
  },
  mounted() {},
  created() {
    this.fetchEgressProfileFile();
    this.fetchFiles();
  },
};
</script>

<style scoped>
table th {
  vertical-align: middle;
  background-color: var(--primary-color);
  color: white;
  font-size: var(--primary-font-size);
  padding: 2px 2px;
}
.evaluation-header {
  background: var(--primary-color) !important;
  border: 1px solid #000 !important;
}
.evaluation-button-div {
  float: left;
}
table td {
  border: 1px solid black;
  text-align: left;
  vertical-align: middle;
  font-size: var(--secondary-font-size);
  padding: 2px 2px;
}
.grabbable {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
</style>